
/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/page';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';
import Link from '../components/link';

export default function Benefits(props) {
    const root = props.match.path;

    return (
      <Page
        articleClass="launch-page"
        {...props}
      >
        <ReactMarkdown
          source={`
## Knowing Your Benefits and Entitlements
As a military member, you receive a wide range of benefits and entitlements. Make sure you understand and take full advantage of all of them. They can include housing, moving, health care, education and pension. Talk to a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL), [personal financial counselor](/pfcMap) or leader if you have questions about your benefits.

[Allowances-and-Benefits]()
### Allowances and Benefits
In addition to your basic pay and benefits, you may also receive “allowances” — or extra money — to offset various expenses connected to your military service. Learn more about these allowances and your benefits to ensure you’re receiving all that you are qualified for.
`}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Retirement-R.jpg"
            alt="Service members smiling and speaking at retirement ceremony"
            title="Retirement"
            href={`${root}/Retirement`}
            link="Check It Out"
          >
            <p>
            Considering military retirement? Begin here! We’ve got checklists, calculators, articles, tips and guidelines for making a smooth transition to civilian life.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-BNA-R.jpg"
            alt="Service member with financial counselor looking at notes"
            title="Understanding the Basic Needs Allowance"
            href={`${root}/BNA`}
            link="Learn More"
          >
            <p>
            Service members, military leaders and service providers can use these resources to learn more about the Basic Needs Allowance and who may qualify.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteArticle-BAH-A.jpg"
            alt="Hand holding keys in front of packing boxes"
            title="Understanding Basic Allowance for Housing"
            href={`${root}/BAH`}
            link="Read Now"
          >
            <p>
            From the time you enter basic training, to your last PCS, the BAH entitlement you receive works to offset the costs you’ll need to spend on housing.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Understanding the Family Supplemental Subsistence Allowance](/assets/downloads/FINRED-FSSA-FS.pdf) 
Military members serving outside the U.S. who have large families may be eligible for the Family Supplemental Subsistence Allowance. Read this fact sheet to learn more.
        
[Education-Benefits]()
### Education Benefits
Don't leave money on the table when it comes to paying for education! Explore the extensive resources available to you as service and family members. They include military tuition assistance, federal student aid, Department of Veterans Affairs education benefits, student loan interest relief and family member resources.
  `}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PSLF-FS.jpg"
            alt="Public Service Loan Forgiveness Fact Sheet"
            title="Understanding the Public Service Loan Forgiveness Program"
            href="/assets/downloads/FINRED-PublicServiceLoanForgiveness-FS.pdf"
            type="Article"
            link="Get the Details"
          >
            <p>
            Service members with qualifying student loans may be eligible for federal public service loan forgiveness. Learn more about the program here.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-529Plans-FS.jpg"
            alt="29 Education Savings Plan Fact Sheet"
            title="529 Education Savings Plans: The Basics for Service Members"
            href="/assets/downloads/FINRED-529Plans-FS.pdf"
            link="Download"
          >
            <p>
            What is a 529 plan and how does it help save for education? This fact sheet explains how the plan works and highlights special considerations for military families.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HigherEd-FS.jpg"
            alt="Education Traps fact sheet"
            title="Avoid These Costly Higher Education Traps"
            href="/assets/downloads/FINRED-HigherEdTraps-FS.pdf"
            type="Fact Sheet"
            link="Download"
          >
            <p>
                Protect yourself from higher education recruitment and enrollment misrepresentations. Learn the signs and corrective actions to take in this fact sheet.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Maximize Your Education Benefits](${root}/TapResourcesPayEducation)
This article outlines the many educational benefits offered to service members, including 0% student loan interest relief. Meet your academic goals with these programs.             
* [Zero Percent Student Loan Interest for Eligible Service Members](/assets/downloads/FINRED-ZeroPercentStudentLoanInterest-FS.pdf)
Certain service qualifies for 0% interest on student loans. Spread the word about this benefit, which can save interest or generate a refund.
* [Zero Percent Student Loan Interest Relief](${root}/ZeroPercentStudentLoanInterest)
Find out about 0% student loan interest relief, who is eligible to receive the benefit and how you can apply or get a refund — all in this article.

[Flexible-Spending-Accounts]()
### Flexible Spending Accounts
Flexible Spending Accounts (FSAs) help service members save on health and dependent care costs.  

Enroll at [FSAFEDS.gov](https://www.fsafeds.gov/) during Federal Benefits Open Season or after a [qualifying life event](https://www.fsafeds.gov/public/pdf/FSAFEDS-QLE-Quick-Reference-Guide%20FINAL-s.pdf) (QLE), such as a permanent change of station (PCS), marriage, divorce, birth or adoption of a child. 

New! You can enroll in a Health Care Flexible Spending Account (HCFSA) for the first time in March 2025. Stay tuned for our education campaign. In the meantime, learn more below.
  `}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-ComingSoon.jpg"
            alt="Coming Soon"
            title="Understanding the Health Care Flexible Spending Account"
            href={`${root}/HCFSA`}
            link="Read Now"
          >
            <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Et laoreet rhoncus fermentum nostra, mus sem fermentum habitasse. Suspendisse amet ullamcorper; placerat primis eleifend sapien.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-Main-R.jpg"
            alt="Two service members sitting outside with their child"
            title="Understanding the Dependent Care Flexible Spending Account Benefit"
            href={`${root}/DCFSA`}
            link="Get the Details"
          >
            <p>
            Military families can consider enrolling in a Dependent Care Flexible Spending Account, a pre-tax benefit for paying for eligible dependent care services.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FSAIntegrated-P.jpg"
            alt="Health and Dependent Care Flexible Spending Account comparison poster"
            title="Compare DCFSA and HCFSA With This Poster (NEW)"
            href="/assets/downloads/FINRED-FSAIntegrated-P.pdf"
            link="View Now"
          >
            <p>
            Confused about which expenses qualify for the DCFSA versus the HCFSA? Deciding if you need both? View them side by side in this poster.
            </p>
          </FeaturedAsset>
        </div>
        <div className="inline-bullet-link">
          <ul>
            <li>
              <b>Enroll in an FSA</b> Visit <Link to="https://www.fsafeds.gov/explore/usmdcfsa">FSAFEDS.gov</Link> to learn more and to enroll during Open Season or after a <Link to="https://www.fsafeds.gov/public/pdf/FSAFEDS-QLE-Quick-Reference-Guide%20FINAL-s.pdf">QLE</Link>.
            </li>
          </ul>
        </div>

        <ReactMarkdown
          source={`
[Housing]()
### Housing
Use the resources you’ll find here to learn more about benefits and protections for members of the military community and put that shiny new set of house keys within reach, avoid scams, receive assistance from counselors and more.
`}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Buy-I.jpg"
            alt="Is Buying Right for You Infographic."
            title="Consider Your Housing Options: Is Buying Right for You?"
            href="/assets/downloads/FINRED-Buy-I.pdf"
            type="Infographic"
            link="Download"
          >
            <p>
                Purchasing a home is a big deal. Check out a breakdown of pros and cons in this infographic to determine whether buying is the right choice for you.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-VAhousing-A.jpg"
            alt="U.S. department of veterans affairs seal and agent with buyer"
            title="House Hunting? Explore the Veterans Affairs Home Loan Benefit"
            href={`${root}/HouseHunting`}
            type="Article"
            link="Read Now"
          >
            <p>
                Choosing the best financing option is a big consideration when buying a home. Learn about options through the Department of Veterans Affairs in this article.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Rent-I.jpg"
            alt="Is Renting Right for You Infographic."
            title="Consider Your Housing Options: Is Renting Right for You? "
            href="/assets/downloads/FINRED-Rent-I.pdf"
            type="Infographic"
            link="Download"
          >
            <p>
                Determining where to live is an important decision. Familiarize yourself with the pros and cons of renting in this infographic to see if it is a good fit for you.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`

[Additional-Information]()
### Additional Information
Make sure you’re taking advantage of all of the military benefits and entitlements available to you and your family. For example, you may qualify for education assistance or other special programs. To learn more, please visit the collection of resources from our trusted and featured collaborators about benefits and entitlements.
`}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ServiceAidSocieties-A-ForTech.jpg"
            alt="Male service member at center"
            title="Seven Unexpected Ways Military Relief Societies Can Help Service Members"
            href={`${root}/ServiceAidSocieties`}
            link="Learn More"
          >
            <p>
            Military relief societies can help with grants and interest-free loans for many expenses, but service members and their families might be surprised by all the other benefits available.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-NewToMilitary-Landing.jpg"
            alt="Maximizing Your Military Money: What to Know"
            title="Joining the Military: What To Know Before You Go"
            href="/Benefits/JoiningtheMilitary"
            type="Resources"
            link="Learn More"
          >
            <p>
                As a new recruit, you’ll need to make several choices early in your career. Use these resources to make informed financial decisions from the very beginning.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TypesOfPay-A.jpg"
            alt="Service Member with sunglasses"
            title="Why Understanding Different Types of Military Pay Is Crucial for Your Finances"
            href="/Money/TypesOfPay"
            link="Read Now"
          >
            <p>
            Your military paycheck includes different types of pay. Knowing what you rate and understanding each type helps ensure you’re paid what you earned.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Knowing Your Benefits and Entitlements Additional Resources](/ToolsAndAddRes/AdditionalRes/Benefits)
Ensure you’re making the most of the benefits and entitlements that come with your military career. Check out these links to learn more.             
`}
        />
      </Page>
    );
}
