/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import Page from '../../components/toolsAndResourcesPage';
import ReactMarkdown from '../../components/markdown';
import Link from '../../components/link';
import SenseCarousel from '../../components/senseCarousel';
import SenseModalCarousel from '../../components/senseModalCarousel';

import slides from './sense-carousel.json';

export default function Sense(props) {
    const root = props.match.path;

    const [clickedSlide, setClickedSlide] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const clickSlide = (index) => {
        setClickedSlide(index);
        setShowModal(true);
    };

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="sense launch-page"
      >
        <div className="title">
          <h2>Sen$e Financial Education App</h2>
          <div className="app-icons">
            <div className="sense-media-container">
              <div className="app-download">
                <div className="mirl-social-media-image">
                  <img
                    src="/assets/img/FINRED-WebsiteImage-Sense-SquareIcon.jpg"
                    alt="Sen$e dollar sign icon"
                    title="Sen$e dollar sign icon"
                  />
                </div>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.powertrain.sense&hl=en_US&gl=US"
                >
                  <img
                    className="button-image"
                    src="/assets/img/Google-play-badge-adjustedsize.png"
                    alt="Google Play button"
                    title="Download on Google Play"
                  />
                </Link>
                <Link
                  to="https://apps.apple.com/us/app/sen%24e/id1443911840"
                >
                  <img
                    className="button-image"
                    src="/assets/img/Download_on_the_App_Store_Badge_US-UK_blk_092917.png"
                    alt="App Store button"
                    title="Download on the App Store"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <SenseCarousel clickSlide={clickSlide} slides={slides} />

        <ReactMarkdown
          source={`
### What’s New

- Version 4.0.1
- Content Updates: Benefits Beyond Pay, Career Development, Tax Basics, and Digital Assets: Cryptocurrency and Non-Fungible Tokens (NFTs)

### App Overview

Discover Sen$e, the free financial education app for Service members, their families and service providers from the Department of Defense. Sen$e is designed to support the unique needs of military life. Packed with timely, factual, unbiased financial information, Sen$e can help you achieve and maintain your financial well-being.

### App Content

Financial information and resources for your military journey:
- Understanding your benefits and entitlements
- Planning your future with the Blended Retirement System
- Building your retirement using the Thrift Savings Plan
- Protecting your finances with the Military Lending Act and Servicemembers Civil Relief Act
- Preparing for military events, including PCS, deployment and promotion

Financial information and resources for life events, including:
- Buying a home or vehicle
- Avoiding financial scams and fraud 
- Planning your career and education
- Expanding your family
- Preparing for your financial future using investing and estate planning

### Tools

Financial tools and calculators to use on the go:
- Financial Well-Being Assessment: Use this quick 12-question assessment to get a snapshot of your financial health and connect with resources. 
- Financial Glossary: Find financial terms to boost your confidence when making financial choices.
- Financial Calculators: Crunch the numbers for major purchases and expenses, like buying a vehicle or purchasing a home.
              `}
        />
        { showModal && (
        <SenseModalCarousel
          closeModal={() => setShowModal(false)}
          slideIndex={clickedSlide}
          slides={slides}
        />
        )}
      </Page>
    );
}
