/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import MobileSlide from './mobileSlide';

export default function SenseCarousel({ clickSlide, slides }) {
    return (
      <div className="hero-container mobile">
        <CarouselProvider
          className="hero-content-box"
          infinite
          isIntrinsicHeight
          visibleSlides={4}
          totalSlides={slides ? slides.length : 0}
          interval={5000}
          isPlaying
        >
          <Slider>
            {
              slides && slides.map((slide, idx) => (
                <Slide index={idx} onClick={() => clickSlide(idx)} key={slide.img}>
                  <MobileSlide
                    img={slide.img}
                    imgAlt={slide.imgAlt}
                  />
                </Slide>
              ))
            }
          </Slider>
          <ButtonBack className="move-button left"><span className="fa fa-angle-left" /></ButtonBack>
          <ButtonNext className="move-button right"><span className="fa fa-angle-right" /></ButtonNext>
          <DotGroup className="hero-dots" />
        </CarouselProvider>
      </div>
    );
}
