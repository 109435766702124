/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';

import Link from './link';
import SimulationLink from './simulationLink';

import selected from './utils/selectNav';

export default function ToolsAdditionalResourceMenu({ setSite }) {
    return (
      <li id="ToolsAndRes" className={`${selected('/ToolsAndAddRes')} header-dropdown`} style={{ flexBasis: '16%', color: '#112e51' }}>
        <a to="/ToolsAndAddRes" className="" aria-expanded="false" aria-controls="extended-nav-section-two">
            Tools and Additional Resources
          <ul id="extended-nav-section-two" className="usa-nav-submenu tools-menu">
            <li>
              <Link to="/ToolsAndAddRes/Sen$e">Sen$e Financial Education App</Link>
            </li>
            <li>
              <Link to="/ToolsAndAddRes/ShareableResources">Shareable Resources</Link>
            </li>
            <li>
              <Link to="/ToolsAndAddRes/AdditionalRes">Additional Resources</Link>
            </li>
            <li>
              <Link to="/ToolsAndAddRes/Calculators">Calculators</Link>
            </li>
            <li>
              <Link to="/FWBA">Financial Well-Being Assessment</Link>
            </li>
            <li>
              <Link to="/ToolsAndAddRes/HowToVideos">How-To Videos</Link>
            </li>
            <li>
              <Link to="/ToolsAndAddRes/NetWorth">Track Your Personal Net Worth</Link>
            </li>
          </ul>
        </a>
      </li>
    );
}


