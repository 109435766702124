/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import ModalView from './modalView';
import ModalMobileSlide from './modalMobileSlide';

export default function SenseModalCarousel ({ closeModal, slideIndex, slides }) {
    return (
      <ModalView closeModal={closeModal} color="blue">
        <div className="hero-container mobile">
          <CarouselProvider
            className="modal-hero-content-box"
            infinite
            isIntrinsicHeight
            naturalSlideHeight={80}
            naturalSlideWidth={36}
            visibleSlides={1}
            totalSlides={9}
            interval={5000}
            currentSlide={slideIndex}
          >
            <Slider className="modal-hero-slider">
              {
              slides && slides.map((slide, idx) => (
                <Slide index={idx} key={slide.img}>
                  <ModalMobileSlide
                    img={slide.img}
                    imgAlt={slide.imgAlt}
                  />
                </Slide>
              ))
            }
            </Slider>
            <ButtonBack className="move-button left"><span className="fa fa-angle-left" /></ButtonBack>
            <ButtonNext className="move-button right"><span className="fa fa-angle-right" /></ButtonNext>
            <DotGroup className="hero-dots" />
          </CarouselProvider>
        </div>
      </ModalView>
    );
}
