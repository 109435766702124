/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';

import ReactMarkdown from '../../components/markdown';

export default function Collaborators ({ crumbs, root, toc }) {
    return (
      <Page
        crumbs={`${crumbs},Featured Collaborators`}
        toc={toc}
      >

        <ReactMarkdown
          source={`
## Featured Collaborators
Our featured collaborators provide essential information to service members on the road to financial readiness. Find the most accurate, up-to-date information on a variety of topics from these trusted agencies and organizations.

- [Federal Trade Commission](https://www.ftc.gov/)
  Visit the Federal Trade Commission website to learn more about consumer protections specific to service members. 
- [Internal Revenue Service](https://www.irs.gov/)
  Visit the Internal Revenue Service website to check your refund status, get your tax record, view your account, make a payment, find forms and more.
- [MilitaryINSTALLATIONS](https://installations.militaryonesource.mil/)
  Visit this official Department of Defense source for installation and state resources for active-duty, Guard and Reserve service and family members.
- [Military OneSource](https://www.militaryonesource.mil/)
  Connect with Military OneSource 24/7/365 to receive expert support, information and services for service members and families worldwide.
- [National Veterans Financial Resource Center (FINVET)](https://www.mirecc.va.gov/visn19/finvet)
  This program from the Department of Veterans Affairs Office of Mental Health and Suicide Prevention links veterans with a wide range of benefits, tools and services that promote financial well-being and mental wellness.
- [U.S. Securities and Exchange Commission](https://www.investor.gov/)
  Research before you invest by checking your investment professional's background, registration status and more and staying up to date on scam alerts.
- [USA.gov](https://www.usa.gov/)
  As the official web portal of the United States government, USA.gov aims to make government information more accessible to the public. 
- [VSAFE](https://vsafe.gov/)
  This one-stop resource helps veterans, service members and their families understand fraud schemes targeting the military community. Learn how to detect and report scams while protecting yourself against identity theft.
        `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


